import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/contact-form'

const Contact = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const hero = {
    title: 'We always enjoy connecting.',
    subtitle:
      "Whether you're a potential customer, partner or someone else, we love to hear from you!",
    img: '/contact/hero.jpg',
    page: 'Contact Us',
  }

  return (
    <Layout location={location} title={siteTitle} hero={hero}>
      <SEO title="Contact Us" />
      <div className="contact-us">
        <ContactForm />
      </div>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
