import React from 'react'

export default function ContactForm() {
  return (
    <>
      <form name="contact" netlify netlify-honeypot="bot-field" hidden>
        <input type="text" name="name" />
        <input type="email" name="email" />
        <textarea name="message"></textarea>
      </form>
      <form className="form" name="contact" method="post">
        <input type="hidden" name="form-name" value="contact" />
        <div className="form__field">
          <label className="form__field-label" htmlFor="name">
            Your Name
          </label>
          <input
            className="form__field-input"
            id="name"
            type="text"
            name="name"
          />
        </div>
        <div className="form__field">
          <label className="form__field-label" htmlFor="email">
            Your Email
          </label>
          <input
            className="form__field-input"
            id="email"
            type="email"
            name="email"
          />
        </div>
        <div className="form__field">
          <label className="form__field-label" htmlFor="message">
            Message
          </label>
          <textarea
            className="form__field-input"
            id="message"
            name="message"
            style={{ minHeight: 150 }}
          />
        </div>
        <div className="form__btn-wrap">
          <button className="form__btn" type="submit">
            Send
          </button>
        </div>
      </form>
    </>
  )
}
